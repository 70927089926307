import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  InputNumber,
  Select,
  Space,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BannerImg from "../assets/images/banner.jpg";
import Serv1 from "../assets/images/icon/serv-1.svg";
import Serv2 from "../assets/images/icon/serv-2.svg";
import Serv3 from "../assets/images/icon/serv-3.svg";
import Serv4 from "../assets/images/icon/serv-4.svg";
import Serv5 from "../assets/images/icon/serv-5.svg";
import Serv6 from "../assets/images/icon/serv-6.svg";
import Prescription from "../assets/images/prescription.png";
import { Container, Row, Col } from "react-bootstrap";

function NotFound() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate();

  return (
    <Main>
      <section className="notfound-wrap">
        <Container>
          <div className="notfound-inner">
            <h2>404</h2>
            <h6>something went wrong!</h6>
            <p>The page you are trying to reach is not available.</p>
            <Link to="/">
              <Button className="btn btn_primary">Back To Home</Button>
            </Link>
          </div>
        </Container>
      </section>
    </Main>
  );
}

export default NotFound;
