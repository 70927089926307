import { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  Button,
  List,
  Avatar,
  Input,
  Drawer,
  Typography,
  Switch,
  Modal,
  Menu,
} from "antd";
import { AuthContext } from "../../context/AuthContext";
import {
  SearchOutlined,
  StarOutlined,
  TwitterOutlined,
  FacebookFilled,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/images/logo.png";
import { Container } from "react-bootstrap";
import LoginModal from "../../pages/Modal/LoginModal";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
// import avtar from "../../assets/images/learn.svg";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const { Title, Text } = Typography;

  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");
  const { isLoggedIn, userProfile, logout } = useContext(AuthContext);

  useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);
  const [login, setLogin] = useState(false);
  const { request } = useRequest();

  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <UserOutlined />
        <Link to={"/appointment-history"}>Account</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={logout}>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  const getProfile = () => {
    request({
      url: `/app/user/get-profile`,
      method: "GET",
      onSuccess: (data) => {
        // console.log(data.data)
      },
      onError: (error) => {
        console.log(error);
        // ShowToast(error, Severty.ERROR)
      },
    });
  };

  useEffect(() => {
    // getProfile();
  }, []);
  return (
    <>
      {console.log(userProfile)}
      {/* <div className="setting-drwer" onClick={showDrawer}>
        {setting}
      </div> */}
      <Container>
        <Row>
          <Col span={24} xs={12} md={6}>
            <div className="logo_wrap">
              <Link to="/">
                <img src={Logo} />
              </Link>
            </div>
          </Col>
          <Col span={24} xs={24} md={18} className="d-md-block d-none mobile_menu">
            <div className="menu_wrap">
              <ul>
                {/* <li>
                  <Link className="active" to={"/"}>
                    Home
                  </Link>
                </li> */}
                <li>
                  <NavLink exact to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  {/* <Link to={"/about"}>About Us</Link> */}
                  <NavLink exact to="/about" activeClassName="active">
                    About Us
                  </NavLink>
                </li>
                <li>
                  {/* <Link to={"/quick-refill"}>Quick refill</Link> */}
                  <NavLink exact to="/quick-refill" activeClassName="active">
                    Quick Refill
                  </NavLink>
                </li>
                <li>
                  {/* <Link to={"/appointment"}>Appointment</Link> */}
                  <NavLink exact to="/appointment" activeClassName="active">
                    Appointment
                  </NavLink>
                </li>
                <li>
                  {/* <Link to={"/contact-us"}>Contact us</Link> */}
                  <NavLink exact to="/contact-us" activeClassName="active">
                    Contact Us
                  </NavLink>
                </li>
                {!userProfile ? (
                  <li className="login-btn">
                    <button
                      onClick={() => setLogin(true)}
                      className="btn_primary"
                    >
                      Login
                    </button>
                    {/* <button onClick={logout} className="btn_primary" >Logout</button> */}
                  </li>
                ) : (
                  <Dropdown overlay={menu} placement="bottomRight">
                    <Button className="btn_primary">
                      <span style={{ marginRight: "12px" }}>
                        {userProfile ? userProfile.userName : ""}
                      </span>{" "}
                      {/* Display user name */}
                      <Avatar icon={<UserOutlined />} />{" "}
                      {/* Display user avatar/icon */}
                    </Button>
                  </Dropdown>

                  // <li className="login-btn">
                  //   <button onClick={() => logout()} className="btn_primary">
                  //     Logout
                  //   </button>
                  //   {/* <button onClick={logout} className="btn_primary" >Logout</button> */}
                  // </li>
                )}
              </ul>
            </div>
          </Col>

          <Col span={24} xs={12} md={18} className="header-control d-md-none d-block">
            <Button
              type="link"
              className="sidebar-toggler"
              onClick={() => onPress()}
            >
              {toggler}
            </Button>
          </Col>
        </Row>
      </Container>
      {login ? <LoginModal show={login} hide={() => setLogin(false)} /> : null}
    </>
  );
}

export default Header;
