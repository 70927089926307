import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { useContext, Suspense } from "react";
import SignIn from "./pages/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import IndexHome from "./pages/IndexHome";
import About from "./pages/About";
import Appointment from "./pages/Appointment";
import QuickRefill from "./pages/QuickRefill";
import ContactUs from "./pages/ContactUs";
import TermsConditions from "./pages/TermsConditions";
import AppointmentHistory from "./pages/AppointmentHistory";
import MedicationHistory from "./pages/MedicationHistory";
import NotFound from "./pages/NotFound";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}
const AppRoutes = () => {
  const { isLoggedIn, userProfile, logout } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<IndexHome />} />
      <Route path="about" element={<About />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="terms-conditions" element={<TermsConditions />} />
      <Route path="not-found" element={<NotFound />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<IndexHome />} />
        <Route path="appointment" element={<Appointment />} />
        <Route path="quick-refill" element={<QuickRefill />} />
        <Route path="appointment-history" element={<AppointmentHistory />} />
        <Route path="medication-history" element={<MedicationHistory />} />
        <Route path="edit-appointment/:id" element={<Appointment />} />
        <Route path="view-appointment/:id" element={<Appointment />} />
        <Route path="edit-quick-refill/:id" element={<QuickRefill />} />
        <Route path="view-quick-refill/:id" element={<QuickRefill />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      {" "}
      <Outlet />{" "}
    </>
  );
};

export default App;
