import React, { useState, useContext, useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";

const RegistrationModal = ({ show, hide }) => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const handleRegister = (values) => {
    setLoading(true);
    console.log(values,"values");
    request({
      url: "/app/auth/register",
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setIsLoggedIn(true);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          localStorage.setItem("token", data.data.token);
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data);
          hide();
          window.location.reload()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      visible={show}
      onCancel={hide}
      className="RegistrationModal"
      okText="Register"
      okButtonProps={{
        form: "register",
        htmlType: "submit",
        loading: loading,
      }}
      footer={null}
    >
      <Form
        id="register"
        form={form}
        onFinish={handleRegister}
        layout="vertical"
        className="registration-form"
      >
        <h3>Register for Rx Care Pharmacy</h3>
        <Form.Item
          label="UserName"
          name="userName"
          className="formControl"
          rules={[{ required: true, message: "UserName is required" }]}
        >
          <Input placeholder="Enter User Name"/>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          className="formControl"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: 'email', message: 'The input is not valid E-mail!',
            },
          ]}
         
        >
          <Input placeholder="Enter User Name"/>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          className="formControl"
          rules={[{ required: true, message: "Please input the password!" }]}
        >
          <Input.Password placeholder="Enter Password"/>
        </Form.Item>

        {/* <Form.Item
          label="Confirm Password"
        //   name="confirm_password"
          className="formControl"
          rules={[{ required: true, message: "Please input the confirm password!" }]}
        >
          <Input.Password placeholder="Enter Confirm Password"/>
        </Form.Item> */}

        <Form.Item className="forgot-login">
          <Button type="primary" className="btn_primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegistrationModal;
