import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  TimePicker,
  InputNumber,
  Select,
  Space,
  Row, Col, 
  Menu,
} from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Dot from "../assets/images/dot.png";
 import { Container } from "react-bootstrap";
import { Severty, ShowToast } from "../helper/toast";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";


function MedicationHistory() {
  const { request } = useRequest();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [selected, setSelected] = useState();
  const [viewData, setViewData] = useState();
  const { confirm } = Modal;
  


  useEffect(() => {
    console.log(pagination, "jjjjj")
    setLoading(true)
    fetchData(pagination)
  }, [refresh])


  const paginate = (pageNumber) => {
    setPagination(prev => ({ current: pageNumber }))
  };


 
  const cancelAppointementModal = (data)=>{
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want cancel the appointment ?? </Button>,
        onOk() {
         cancelAppointement(data)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 500);
  }
  const cancelAppointement = (data)=>{
    setLoading(true);
    request({
      url: `/app/quickRefill/cancel-quick-refill/${data._id}`,
      method: 'PUT',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);

      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: `/app/quickRefill/my-medicinie?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        setList(data.data)
        // setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  return (
    <Main>
          <section className="appointment-wrap">
            <Container>
              <Row>
                <Col span={24} md={6}>
                  <div className="appointment-left">
                    <ul>
                      <li>
                        <a href="appointment-history">Appointment history</a>
                      </li>
                      <li>
                        <a href="medication-history" className="active">Medication history</a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col span={24} md={18}>
                  <div className="appointment-right">
                      <div className="appointment-list">
                      { list.length > 0 ? list.map((data)=>
 <div className="appointment-inner-list">
 <div className="appointment-left">
   <h5>{moment(data.date).format("dddd,YYYY-MM-DD")}</h5>
   <p>Rx care pharmacy & compounding</p>
 </div>
 <div className="appointment-right">
    <div className="btn_group">
      
    
        <Link to={`/view-quick-refill/${data._id}`}>
       <Button className="btn btn_border">View</Button>
       </Link>
       {data.status=="pending" ? 
       <>
        <Button onClick={()=>cancelAppointementModal(data)} className="btn btn_update">Cancel</Button>
       <Link to={`/edit-quick-refill/${data._id}`}>
       <Button className="btn btn_cancel">Update</Button>
       </Link>
       </>
      
        :null}
       {/* <Button className="btn btn_primary">Update</Button> */}
    </div>
 </div>
</div>
                  ):"No Appointment"}
                       
                        
                        {/* <div className="appointment-inner-list">
                          <div className="appointment-left">
                            <h5>COVID-19 spikevax bivalent Vaccine</h5>
                            <p>June 06, 2023 - 11:15 am</p>
                          </div>
                          <div className="appointment-right">
                            <div className="btn_group">
                                <Button className="btn btn_border">View</Button>
                            </div>
                          </div>
                        </div>
                        <div className="appointment-inner-list">
                          <div className="appointment-left">
                            <h5>Influenza Vaccine</h5>
                            <p>Jan 26, 2023 - 02:12 pm</p>
                          </div>
                          <div className="appointment-right">
                                <div className="btn_group">
                                    <Button className="btn btn_border">View</Button>
                                </div>
                          </div>
                        </div>
                        <div className="appointment-inner-list">
                          <div className="appointment-left">
                            <h5>Influenza Vaccine</h5>
                            <p>Jan 26, 2023 - 02:12 pm</p>
                          </div>
                          <div className="appointment-right">
                                <div className="btn_group">
                                    <Button className="btn btn_border">View</Button>
                                </div>
                          </div>
                        </div> */}
                      </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
    </Main>
  );
}



export default MedicationHistory;
