import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  InputNumber,
  Select,
  Space,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BannerImg from "../assets/images/banner.jpg";
import Serv1 from "../assets/images/icon/serv-1.svg";
import Serv2 from "../assets/images/icon/serv-2.svg";
import Serv3 from "../assets/images/icon/serv-3.svg";
import Serv4 from "../assets/images/icon/serv-4.svg";
import Serv5 from "../assets/images/icon/serv-5.svg";
import Serv6 from "../assets/images/icon/serv-6.svg";
import Prescription from "../assets/images/prescription.png";
import { Container, Row, Col } from "react-bootstrap";

const options = {
  navText: [
    "<img src='https://d1yfv825grupco.cloudfront.net/invent/public/img/s-arrow-l.webp' alt='arrow'>",
    "<img src='https://d1yfv825grupco.cloudfront.net/invent/public/img/s-arrow-r.webp' alt='arrow'>",
  ],
  loop: true,
  margin: 0,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

function TermsConditions() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate();
  const contact = () => {
    window.location.assign("/contact-us");
  };
  return (
    <Main>
      <section className="terms-conditions-wrap ">
        <Container>
          <div className="terms_inner">
            <h4>Data Storage:</h4>
            <p>
              No personal information is stored in the appointment application
              or on any of our data processing servers. This application
              collects data directly from the appointment frontend and sends the
              order directly back to the pharmacy backend. No health data is
              stored, only contact information typed in by individuals with
              permission is stored for use by provider.
            </p>
          </div>
          <div className="terms_inner">
            <h4>How secure is the information I provide?</h4>
            <p>
              We are committed to securing your personal information. We use
              technology that is designed for use with secure servers. Secure
              Sockets Layer (SSL) technology encrypts the information given for
              the purposes of making an appointment as it is transmitted over
              the internet.
            </p>
          </div>
          <div className="terms_inner">
            <h4>Why do we use it?</h4>
            <ul>
              <li>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </li>
              <li>
                Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </li>
              <li>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum.
              </li>
              <li>
                Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.{" "}
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="number_wrap space-cls">
        <Container>
          <div className="number_box contact_sec">
            <Row className="align-items-center">
              <Col md="8">
                <h3>You’ll need your Rx care Pharmacy account to sign up.</h3>
              </Col>
              <Col md="4">
                <div className="contact_inner">
                  <Button onClick={() => contact()} className="btn btn_theme">
                    Contact Us
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Main>
  );
}

export default TermsConditions;
