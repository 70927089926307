import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  TimePicker,
  InputNumber,
  Select,
  Space,
  Row,
  Col,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Dot from "../assets/images/dot.png";
import { Container } from "react-bootstrap";
import { Severty, ShowToast } from "../helper/toast";
import moment from "moment";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function AppointmentHistory() {
  const { request } = useRequest();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [selected, setSelected] = useState();
  const [viewData, setViewData] = useState();
  const { confirm } = Modal;



  useEffect(() => {
    console.log(pagination, "jjjjj")
    setLoading(true)
    fetchData(pagination)
  }, [refresh])


  const paginate = (pageNumber) => {
    setPagination(prev => ({ current: pageNumber }))
  };

  const cancelAppointementModal = (data) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want cancel the appointment ?? </Button>,
        onOk() {
          cancelAppointement(data)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 500);
  }
  const cancelAppointement = (data) => {
    setLoading(true);
    request({
      url: `/app/appointment/cancel-appointment/${data._id}`,
      method: 'PUT',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);

      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const [selectedItem, setSelectedItem] = useState(null);

  const handleDotClick = (index) => {
    setSelectedItem(selectedItem === index ? null : index);
  };

  const handleAction = (action, item) => {
    // Perform action based on the selected item (view, edit, delete, etc.)
    console.log(`${action} clicked for item:`, item);
  };






  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: `/app/appointment/my-appointment?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        setList(data.data)
        // setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  return (
    <Main>
      <section className="appointment-wrap">
        <Container>
          <Row>
            <Col span={24} md={6}>
              <div className="appointment-left">
                <ul>
                  <li>
                    <a href="appointment-history" className="active">
                      Appointment history
                    </a>
                  </li>
                  <li>
                    <a href="medication-history">Medication history</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col span={24} md={18}>
              <div className="appointment-right">
                <div className="appointment-list">
                  {/* <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={true}
                    className="ant-border-space"
                  /> */}
                  {list.length > 0 ? list.map((data, index) =>
                    <div className="appointment-inner-list">
                      <div className="appointment-left">
                        <h5>{data.serviceId ? data.serviceId : ""}</h5>
                        <p>{data ? moment(data.date).format("dddd,YYYY-MM-DD") : ""}</p>
                        <p>{data ? data.from ? (data.from + "-" + data.to) : '' : ''}</p>
                      </div>
                      <div className="appointment-right">
                        {data.status === "pending" ? <span className="completed" style={{
                          textTransform: 'uppercase',
                        }} onClick={() => cancelAppointementModal(data)} >{data.status}</span> :
                          <span style={{
                            textTransform: 'uppercase',
                          }} className={`completed ${data.status == "accepted" ? "green" : data.status == "rejected" ? "red" : "blue"}`} >{data.status}</span>}
                        <span className="position-relative">
                          {data.status == "rejected" || data.status == "accepted" ? null :
                            <span className="dot-wrap">
                              <img
                                src={Dot}
                                alt="Dropdown Trigger"
                                onClick={() => handleDotClick(index)}
                                className={selectedItem === index ? 'active' : ''}
                              />
                            </span>}
                          {selectedItem === index && (
                            <div className="dropdown-content">

                              {/* <button onClick={() => handleAction('View', data)}>View</button> */}
                              <Link to={`/edit-appointment/${data._id}`}>
                                <button >Edit</button>
                              </Link>

                              <button onClick={() => cancelAppointementModal(data)}>Cancel</button>
                              {/* Add more dropdown items/buttons as needed */}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  ) : "No Appointment"}

                  {/* <div className="appointment-inner-list">
                          <div className="appointment-left">
                            <h5>COVID-19 spikevax bivalent Vaccine</h5>
                            <p>Sep 28, 2023 - 04:45 pm</p>
                          </div>
                          <div className="appointment-right">
                             <img src={Dot} /> 
                          </div>
                        </div>
                        <div className="appointment-inner-list">
                          <div className="appointment-left">
                            <h5>Influenza Vaccine</h5>
                            <p>Jan 26, 2023 - 02:12 pm</p>
                          </div>
                          <div className="appointment-right">
                            <span className="canceled">Cancelled</span>
                          </div>
                        </div>
                        <div className="appointment-inner-list">
                          <div className="appointment-left">
                            <h5>Influenza Vaccine</h5>
                            <p>Jan 26, 2023 - 02:12 pm</p>
                          </div>
                          <div className="appointment-right">
                            <span className="completed">Completed</span>
                          </div>
                        </div> */}


                </div>
                {/* <div className="appointment-inner-list">
          {Array.from({ length: pagination.total }, (_, i) => (
            <button key={i} onClick={() => paginate(i + 1)}>
              {i + 1}
            </button>
          ))}
        </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Main>
  );
}

export default AppointmentHistory;
