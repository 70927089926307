import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  TimePicker,
  InputNumber,
  Select,
  Space,
  Row,
  Col,
  Menu,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MapImg from "../assets/images/map.png";
import { Container } from "react-bootstrap";
import TextArea from "antd/lib/input/TextArea";

function ContactUs() {
  const { request } = useRequest();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country_code: "",
  });
  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile: value.slice(data.dialCode.length),
    });
  };
  const onSubmit = (values) => {
    console.log("test------", values);
    if (!mobileNumber.mobile)
      return ShowToast("Please enter mobile number ", Severty.ERROR);
    if (mobileNumber.mobile.length <= 7 || mobileNumber.mobile.length >= 15) {
      return ShowToast("Please enter 8 to 12 digits", Severty.ERROR);
    }
    let payload = {};
    payload.name = values.name;
    payload.email = values.email;
    payload.message = values.message;
    payload.phoneNumber = mobileNumber.mobile;
    payload.countryCode = mobileNumber.country_code;
    setLoading(true);
    request({
      url: "/app/contact/add",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          console.log(data, "data---------");
          window.location.assign("/");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Main>
      <section className="about-slider">
        <div className="banner-tit-bg">
          <img src={MapImg} />
        </div>
      </section>
      <section className="contact-page appointment-sec space-cls">
        <Container>
          <Row>
            <Col md={12}>
              <div className="contact_info">
                <div className="help-wrap">
                  <h5>We're here to help</h5>
                  <p>Rx Care Pharmacy 4695 Canad Way,
                    Burnaby, BC
                    V5G 1K9</p>
                </div>
                <div className="help-wrap">
                  <h5>Call support</h5>
                  <a href="tel:+1 (604) 428-7911">+1 (604) 428-7911</a>
                </div>
                <div className="help-wrap">
                  <h5>Email support</h5>
                  <a>info@rxcarepharmacy.ca</a>
                </div>
                <div className="help-wrap">
                  <h5>Working Hours</h5>
                  <p>Mon-Fri: 9:00 am - 6:00 pm</p>
                  <p>Sat: 10:00 am - 3:00 pm</p>
                  <p>Sunday Closed</p>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="contactForm">
                <Form form={form} onFinish={onSubmit}>
                  <Row gutter={12}>
                    <Col span={24} md="24">
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          { required: true, message: "Please enter your Name" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item label="Mobile Number">
                        <PhoneInput
                          inputProps={{
                            name: "mobile",
                            required: true,
                            autoFocus: false,
                          }}
                          isValid={(value, country) => {
                            if (value.match(/1234/)) {
                              return (
                                "Invalid value: " + value + ", " + country.name
                              );
                            } else if (value.match(/1234/)) {
                              return (
                                "Invalid value: " + value + ", " + country.name
                              );
                            } else {
                              return true;
                            }
                          }}
                          country={"ca"}
                          value={
                            mobileNumber
                              ? (mobileNumber.country_code
                                ? mobileNumber.country_code
                                : "+1") +
                              (mobileNumber.mobile
                                ? mobileNumber.mobile
                                : null)
                              : ""
                          }
                          onChange={handleChange}
                        />
                      </Form.Item>
                      {/* <Form.Item
                              label="Mobile number"
                              name="phoneNumber"
                              rules={[{ required: true, message: 'Please enter your Mobile number!' }]}
                            >
                            <Input placeholder="6045867955" />
                          </Form.Item> */}
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is required" },
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24} md="24">
                      <Form.Item
                        label="Message"
                        name="message"
                        rules={[{ required: true, message: "" }]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={24}>
                      <div className="formSubmit">
                        <Button htmlType="submit" className="btn_primary">
                          Send
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="number_wrap space-cls">
        <Container>
          <div className="number_box contact_sec">
            <Row className="align-items-center">
              <Col md={16}>
                <h3>You’ll need your Rx care Pharmacy account to sign up.</h3>
              </Col>
              <Col md={8}>
                <div className="contact_inner">
                  <Button className="btn btn_theme">Contact Us</Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section> */}
    </Main>
  );
}

export default ContactUs;
