import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Modal
} from "antd";
import signinbg from "../assets/images/logo.png";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";
import encryptDecrypt from "../helper/encryptDecrypt";
const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const SignIn = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const { request } = useRequest()
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const onFinish = (values) => {
    onSubmit(values)
  };
  const onSubmit = (values) => {
    const { email, password } = values
    if (!email) return ShowToast('Please enter email to sign in', Severty.ERROR)
    const payload = { password }
    if (!email) return ShowToast('Please enter valid email ', Severty.ERROR)
    setLoading(true)
    payload.email = email
    request({
      url: '/v1/webaapi/login',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          console.log(data)
          setIsLoggedIn(true)
          if (rememberMe) {
            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(values.password);

            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('ykmCe2AYEFTHobn', emailEncrypt);
            localStorage.setItem('ouUsippetc8S4Ry', passwordEncrypt);
          }
          else {
            localStorage.removeItem('ykmCe2AYEFTHobn');
            localStorage.removeItem('ouUsippetc8S4Ry');
          }
          localStorage.setItem('userProfile', JSON.stringify(data.data))
          ShowToast(data.message, Severty.SUCCESS)
          setUserProfile(data.data)
          navigate('/')
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  }


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  useEffect(() => {

    const savedEmail = localStorage.getItem('ykmCe2AYEFTHobn');
    const savedPassword = localStorage.getItem('ouUsippetc8S4Ry');

    if (savedEmail && savedPassword) {

      var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt.decryptEmail(savedPassword);

      form.setFieldsValue({ email: originalEmail, password: originalPassword });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }

  }, []);

  return (
    <>
      {
        loading && <Loader />
      }
      <Layout className="layout-default layout-signin" style={{ backgroundColor: "white" }}>
        <Content className="signin">
          <div className="signin-box">
            <div className="signup-logo">
              <img src={signinbg} alt="" />
            </div>
            <Row justify="space-around">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
              >
                <div className="signup-form">
                  <Title className="mb-15">Sign In</Title>
                  <Title className="font-regular text-muted" level={5}>
                    Enter your email and password to sign in
                  </Title>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                        {
                          type: 'email', message: 'The input is not valid E-mail!',
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                      className="username"
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="Password" />
                    </Form.Item>
                    <div className="forgot-pass">
                      <Form.Item
                        name="remember"
                        className="aligin-center"
                        valuePropName="checked"
                      >
                        <Switch checked={rememberMe}
                          onChange={(checked) => setRememberMe(checked)} />
                        Remember me
                      </Form.Item>
                      <Form.Item
                        name="remember"
                        className="aligin-center"
                        valuePropName="checked"
                      >
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        SIGN IN
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Content>

      </Layout >
    </>
  )
}

export default SignIn
