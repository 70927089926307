import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  TimePicker,
  InputNumber,
  Select,
  Space,
  Row,
  Col,
  Upload,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import UploadImg from "../assets/images/upload.png";
import ReFill from "../assets/images/refil.png";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import moment from "moment";

function QuickRefill() {
  let { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const params = useParams()
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [precausion, setPrecausion] = useState('');

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values)
    const prescription = [
      values.prescription1,
      values.prescription2,
      values.prescription3,
      values.prescription4,
      values.prescription5,
      values.prescription6,
    ]
    let formData = new FormData();

    // Append image file to FormData
    formData.append('image', values.uploadPrescription.file.originFileObj);


    // Append prescription array to FormData
    formData.append('prescription1', values.prescription1);
    formData.append('prescription2', values.prescription2);
    formData.append('prescription3', values.prescription3);
    formData.append('prescription4', values.prescription4);
    formData.append('prescription5', values.prescription5);
    formData.append('prescription6', values.prescription6);
    formData.append('note', values.note);
    formData.append('email', values.email);
    formData.append('phoneNumber', values.phoneNumber);
    formData.append('date', values.date);
    formData.append('location', values.location);
    formData.append('rxNumber', values.rxNumber);
    // payload.uploadPrescription = precausion ? precausion : ''
    // payload.prescription = [
    //   values.prescription1,
    //   values.prescription2,
    //   values.prescription3,
    //   values.prescription4,
    //   values.prescription5,
    //   values.prescription6,
    // ];
    setLoading(true);
    request({
      url: params && params.id ? `/app/quickRefill/edit/${params.id}` : `/app/quickRefill/add`,
      // url: "/app/quickRefill/add",
      method: "POST",
      data: formData,
      header: {
        "Content-Type": "multipart/form-data",
        // Assuming your API returns some information about the uploaded file
      },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          window.location.assign("/medication-history");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    if (!params.id) return
    setLoading(true);
    request({
      url: `/app/quickRefill/get-quick-refil/${params.id}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.prescription[0], "user data-------------");
        setLoading(false);
        form.setFieldsValue({
          ...data.data, prescription1: data.data.prescription[0], service: data.data.serviceId, date: moment(data.data.endDate), prescription2: data.data.prescription[1], prescription3: data.data.prescription[2],
          prescription4: data.data.prescription[3],
          prescription5: data.data.prescription[4],
          prescription6: data.data.prescription[5]


        })

        // ShowToast(data.message, Severty.SUCCESS)
        // setServiceList(data.data);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        // console.log(error.response.data, "Error")
        setLoading(false);
      },
    });
  }, [params.id]);
  const handleImageUpload = (info) => {
    if (info.file.status === "done") {
    } else {
      console.error("Image upload failed.");
      const formData = new FormData();
      formData.append("image", info.file.originFileObj);

      request({
        url: "/admin/service/upload",
        method: "POST",
        data: formData,
        header: {
          "Content-Type": "multipart/form-data",
          // Assuming your API returns some information about the uploaded file
        },
        onSuccess: (data) => {
          setLoading(false);
          console.log(data, "data")
          if (data.status) {
            setPrecausion(data.data)
            ShowToast(data.message, Severty.SUCCESS);
            window.location.assign("/medication-history");
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  };
  const contact = () => {
    window.location.assign("/contact-us");
  };

  useEffect(() => {
    console.log(page, "page")
    if (page.startsWith("view-quick-refill")) {
      setModalView(true);
    }
    else {
      setModalView(false);
    }
  }, [page])
  return (
    <Main>
      <section className="about-slider">
        <div className="banner-tit-bg">
          <img src={ReFill} />
        </div>
        <Container>
          <div className="about-title">
            <h2>Quick Refill</h2>
          </div>
        </Container>
      </section>
      <section className="appointment-sec space-cls">
        <Container>
          <Form form={form} onFinish={onFinish}>
            <h2>Enter your prescription</h2>
            <div className="prescription">
              <Row gutter={16}>
                {/* <Col span={24} md={24} lg={12}>
                  <Form.Item label="1" name="prescription1">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item label="2" name="prescription2">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item label="3" name="prescription3">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item label="4" name="prescription4">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item label="5" name="prescription5">
                    <Input />
                  </Form.Item>
                </Col>  */}
                <Col span={24} md={24} lg={12}>
                  <Form.Item label="Rx Number" name="rxNumber">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item
                    label="Upload prescription"
                    name="uploadPrescription"
                    className="uploadWrap"
                  >
                    <Upload onChange={(e) => handleImageUpload(e)}>
                      <Button>
                        <img src={UploadImg} /> Click here to upload
                        Prescription
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="pharmacy_wrap">
              <Row gutter={16}>
                <Col span={24} md={24} lg={12}>
                  <Form.Item
                    label="Pharmacy location*"
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Rx care pharmacy & compounding",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="Rx care pharmacy & compounding">
                        Rx care pharmacy & compounding
                      </Option>
                    </Select>
                    {/* <Input /> */}
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item
                    label="Pickup"
                    name="date"
                    rules={[
                      { required: true, message: "Please select your Pickup*" },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item
                    label="Mobile number"
                    name="phoneNumber"
                    rules={[{ required: true, message: "6045867955" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={24} lg={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "" },
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md="24" className="note-wrap">
                  <Form.Item
                    label="Note"
                    name="note"
                  // rules={[{ required: true, message: "" }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <div>
                  Note: Prescription is requied
                </div>
                {!modalView ?
                  <Col span={24} md={24}>
                    <div className="formSubmit">
                      <Button htmlType="submit" className="btn_primary">
                        Order
                      </Button>
                    </div>
                  </Col> : null}
              </Row>
            </div>
          </Form>
        </Container>
      </section>
      <section className="number_wrap space-cls">
        <Container>
          <div className="number_box contact_sec">
            <Row className="align-items-center">
              <Col md={16}>
                <h3>You’ll need your Rx care Pharmacy account to sign up.</h3>
              </Col>
              <Col md={8}>
                <div className="contact_inner">
                  <Button onClick={() => contact()} className="btn btn_theme">
                    Contact Us
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Main>
  );
}

export default QuickRefill;
