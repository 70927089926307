import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import ReactDOM from "react-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Space,
  Row,
  Col,
  InputNumber,
  Modal,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { useNavigate } from "react-router";
import { isTypedArray } from "lodash";
import { Link } from "react-router-dom";
import RegistrationModal from "./RegistrationModel"; 
const { Option } = Select;

//UI35736

const LoginModal = ({ show, hide }) => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [backValue, setBackValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [registrationModel, setRegistrationModel] = useState(false);

  const handleCreate = (values) => {
    const payload = {};
    console.log(values, "values");
    setLoading(true);
    request({
      // url: `${data ? `/admin/psychologist/edit-psychologist/${data._id}` : `/admin/psychologist/add-psychologist`}`,
      url: "/app/auth/login",
      method: "POST",
      data: values,
      onSuccess: (data) => {
        console.log(data, "----data----");
        setLoading(false);
        if (data.status) {
          setIsLoggedIn(true);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          localStorage.setItem("token", data.data.token);
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data);
          hide();
          // navigate('/question-manager')
          // window.location.assign("/expathy/question-manager");

          // hide()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        // console.log(error.response.data, "Error")
        setLoading(false);
      },
      // onErrorSubmit: (data) => {
      //   console.log(data)
      // }
    });
  };

  const handleRegisterClick = () => {
    // hide();
    setRegistrationModel(true);
  };

  // const cancelChange =

  return (
    // Assuming you have imported the necessary components and styles
<>
    <Modal
      visible={registrationModel ? false :show}
      onCancel={hide}
      className="LoginModal"
      okText="Ok"
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      footer={null}
    >
      <Form
        id="create"
        form={form}
        onFinish={handleCreate}
        layout="vertical"
        className="change-pw"
      >
        <h3>Login to Rx Care Pharmacy</h3>
        <Form.Item
          label="UserId"
          name="userId"
          className="formControl"
          rules={[{ required: true, message: "UserId is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          className="formControl"
          rules={[{ required: true, message: "Please input the password!" }]}
        >
          <Input.Password />
        </Form.Item>
        {/* <Form.Item className="forgot-pw">
          <a href="">Forgot Password?</a>
        </Form.Item> */}
        <Form.Item className="forgot-login">
          {/* Corrected htmlType prop */}
          <Button type="primary" className="btn_primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>

        <Form.Item className="forgot-login">
          <h4>
            Don’t have an account? <a onClick={handleRegisterClick}>Register</a>
          </h4>
        </Form.Item>
      </Form>
    </Modal>
      {registrationModel && <RegistrationModal show={registrationModel} hide={() => setRegistrationModel(false)} />}
      </>
  );
};

export default LoginModal;
