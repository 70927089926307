import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  InputNumber,
  Select,
  Space,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AboutBanner from "../assets/images/about-banner.png";
import AboutImg from "../assets/images/about-1.png";
import AboutImg1 from "../assets/images/about-2.png";
import Team1 from "../assets/images/team1.png";
import Team2 from "../assets/images/team2.png";
import Team3 from "../assets/images/team3.png";
import Team4 from "../assets/images/team4.png";
import { Container, Row, Col } from "react-bootstrap";

function About() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [modalTeam, setModalTeam] = useState(false);
  const [teamDataModal, setTeamDataModal] = useState({});
  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true);
    request({
      url: `/app/team/get-list`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.list, "user data-------------");
        setLoading(false);
        setTeamData(data.data.list)
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        // console.log(error.response.data, "Error")
        setLoading(false);
      },
    });
  }, []);

  const contact = () => {
    window.location.assign("/contact-us");
  };
  return (
    <Main>
      <section className="about-slider">
        <div className="banner-tit-bg">
          <img src={AboutBanner} />
        </div>
        <Container>
          <div className="about-title">
            <h2>About Us</h2>
          </div>
        </Container>
      </section>
      <section className="about-sec space-cls">
        <Container>
          <Row>
            <Col md="6">
              <div className="about-cont">
                <div className="main-title">
                  <h2>
                    About Rx care <span className="themeColor">Pharmacy</span>
                  </h2>
                </div>
                <p>
                  We are Rx Care Pharmacy & Compounding, your friendly neighbourhood pharmacy proudly serving
                  Burnaby and its surrounding areas! We're not your average pharmacy – we're a locally owned and
                  operated gem that's all about exceptional patient care and building real, lasting connections with our
                  fantastic clients.
                </p>
                <p>
                  Established in 2017 and nestled right in the heart of Burnaby, our full-service pharmacy is your go-to
                  spot for a wide range of services and a fantastic selection of over-the-counter and healthcare products.
                  For over six years, we've been on this awesome journey, thriving because of our top-notch customer
                  service and commitment to excellence in pharmacy practice. Our incredible team of pharmacy pros is
                  here to make sure you get personalized and efficient service that suits your needs.
                </p>
                <p>
                  But we're more than just a pharmacy – we're committed to supporting our community with
                  collaboration, compassion, and respect. So, whether you're a regular or it's your first visit, we can't wait
                  to have the opportunity to serve you and your family today!
                </p>
                {/* <p>
                  We’re different. And we love it!
                </p> */}
                {/* <p>
                 As an independent pharmacy, we pride ourselves on putting you first. We can self-direct our operations
to benefit our clients and our community. At Rx Care Pharmacy, we define ourselves by building strong
and unique patient-to-pharmacist relationships and partnering with you to achieve your health and
wellness goals.
                </p> */}
              </div>
            </Col>
            <Col md="6">
              <div className="about-img">
                <img src={AboutImg} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-independent-sec space-cls">
        <Container>
          <Row>
            <Col md="6">
              <div className="about-img">
                <img src={AboutImg1} />
              </div>
            </Col>
            <Col md="6">
              <div className="about-cont">
                <div className="main-title">
                  <h2>
                    About Independent{" "}
                    <span className="themeColor">Pharmacy</span>
                  </h2>
                  <p>We’re different. And we love it!</p>
                </div>
                <p>
                  As an independent pharmacy, we pride ourselves on putting you
                  first. We can self-direct our operations to benefit our
                  clients and our community. At Rx care Pharmacy, we define
                  ourselves by building strong and unique patient-to-pharmacist
                  relationships and partnering with you to achieve your health
                  and wellness goals
                </p>
                <p>
                  What does this mean for you and your family? We are flexible
                  and can tailor our services to meet your specific healthcare
                  needs. We can put you above all else. Let us show you how our
                  independently practicing pharmacy team can be your best
                  partner in good health!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="team-sec space-cls">
        <Container>
          <div className="main-title">
            <h2>
              Our <span className="themeColor">Team</span>
            </h2>
          </div>
          <Row>
            {teamData.length > 0 ? teamData.map((data) =>

              <Col md="6" lg="3" onClick={() => {
                setModalTeam(true);
                setTeamDataModal(data);
              }} >
                <div className="team-box">
                  <div className="teamImg">
                    <img src={`https://rxcarepharmacy.ca/api${data.icon}`} />
                  </div>
                  <div className="teamUser">
                    <h4>{data.name} {data.designation ? "(" + data.designation + ")" : null}</h4>
                    <p>{data.qualification}</p>
                  </div>
                </div>
              </Col>
            ) : null}

            {/* <Col md="6" lg="3" >
              <div className="team-box">
                <div className="teamImg">
                  <img src={Team2} />
                </div>
                <div className="teamUser">
                  <h4>Susan Moore (Pharmacist/Owner)</h4>
                  <p>RPh, Pharm D, BScPharm</p>
                </div>
              </div>
            </Col>
            <Col md="6" lg="3" >
              <div className="team-box">
                <div className="teamImg">
                  <img src={Team3} />
                </div>
                <div className="teamUser">
                  <h4>Michael Brown (Pharmacy Technician)</h4>
                  <p>RPhT</p>
                </div>
              </div>
            </Col>
            <Col md="6" lg="3" >
              <div className="team-box">
                <div className="teamImg">
                  <img src={Team4} />
                </div>
                <div className="teamUser">
                  <h4>Linda Anderson (Pharmacist)</h4>
                  <p>RPh, Pharm D, BScPharm, BSc</p>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
      <section className="number_wrap space-cls">
        <Container>
          <div className="number_box contact_sec">
            <Row className="align-items-center">
              <Col md="8">
                <h3>You’ll need your Rx care Pharmacy account to sign up.</h3>
              </Col>
              <Col md="4">
                <div className="contact_inner">
                  <Button onClick={() => contact()} className="btn btn_theme">
                    Contact Us
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {modalTeam ? (
        <Modal
          visible={modalTeam}
          onCancel={() => setModalTeam(false)}
          footer={null}
          className="ModalCls teamModal"
        >
          <Row>
            <Col>
              <div className="ServiceDesc">
                <div className="team-modal-img">
                  <img
                    src={
                      teamDataModal && teamDataModal.icon
                        ? "http://62.72.3.159:3000" + teamDataModal.icon
                        : null
                    }
                  />
                </div>
                <div className="ServDesc">
                  <h2>{teamDataModal.name}  {teamDataModal.designation ? "(" + teamDataModal.designation + ")" : null}</h2>
                  <p>{teamDataModal.qualification}</p>
                  <p>{teamDataModal.description}</p>
                </div>
              </div>
              {/* {serviceData.description} */}
            </Col>
          </Row>
        </Modal>
      ) : null}
    </Main>

  );
}

export default About;
