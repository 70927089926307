import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  TimePicker,
  InputNumber,
  Select,
  Space,
  Row,
  Col,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AboutBanner from "../assets/images/appointment.png";

import { Container } from "react-bootstrap";
import moment from "moment";

const { Option } = Select;

function Appointment() {
  const params = useParams()
  // const { appointmentData } = location.state;
  console.log(params, "appointmentData")
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [slotData, setSlotData] = useState();
  const [form] = Form.useForm();
  const [login, setLogin] = useState(false);
  const [serviceText, setServiceText] = useState(0);

  let token = localStorage.getItem("userProfile")
    ? localStorage.getItem("userProfile")
    : null;
  useEffect(() => {
    token ? setLogin(false) : setLogin(true);
  }, [token]);
  //   useEffect(() => {
  //     getServiceList();
  // }, [])
  useEffect(() => {
    setLoading(true);
    request({
      url: "/app/appointment/service-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data, "user data-------------");
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setServiceList(data.data);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        // console.log(error.response.data, "Error")
        setLoading(false);
      },
    });
  }, []);
  useEffect(() => {
    if (!params.id) return
    setLoading(true);
    request({
      url: `/app/appointment/get-appointment/${params.id}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data, "user data-------------");
        setLoading(false);
        handleDateChange(moment(data.data.endDate))

        form.setFieldsValue({ ...data.data, slot: data.data.slot, service: data.data.serviceId, date: moment(data.data.endDate) })

        // ShowToast(data.message, Severty.SUCCESS)
        // setServiceList(data.data);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        // console.log(error.response.data, "Error")
        setLoading(false);
      },
    });
  }, [params.id]);
  const onFinish = (values) => {
    let payload = values
    let from
    let to
    slotData &&
      slotData.slots.length > 0 &&
      slotData.slots.map((data) => {
        if (data._id == values.slot) {
          return from = data.from,
            to = data.to
        }
      })
    payload.from = from
    payload.to = to
    setLoading(true);
    request({
      url: params && params.id ? `/app/appointment/edit/${params.id}` : `/app/appointment/add`,
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          window.location.assign("/appointment-history");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleDateChange = (date) => {
    const getDate = moment(date);
    const dayOfWeek = getDate.format("dddd");
    request({
      url: `/app/slot/get-slot?day=${dayOfWeek.toLowerCase()}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data, "user data-------------");
        setLoading(false);
        setSlotData(data.data);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        // console.log(error.response.data, "Error")
        setLoading(false);
      },
    });
  };
  const hanldeSleectChange = (e) => {
    // alert(e)
    if (e === "Other") {
      setServiceText(2)
    }
    else {
      window.location.assign("https://immunizebc.ca/", "_blank")
      // setServiceText(1)
    }
    //  alert(serviceText)
  }

  const contact = () => {
    window.location.assign("/contact-us");
  };
  return (
    <Main>
      <section className="about-slider">
        <div className="banner-tit-bg">
          <img src={AboutBanner} />
        </div>
        <Container>
          <div className="about-title">
            <h2>Appointment</h2>
          </div>
        </Container>
      </section>
      <section className="appointment-sec space-cls">
        <Container>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={24} md={24}>
                <Form.Item
                  label="Select Service"
                  name="service"
                  rules={[
                    { required: true, message: "Please select your service!" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Service"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={hanldeSleectChange}
                  >

                    <Option value="Moderna COVID-19 SPIKEVAX BIVALENT Vaccine
Administration">
                      Moderna COVID-19  SPIKEVAX BIVALENT AND  Influenza  Vaccine
                      Administration
                    </Option>
                    <Option value="Other">
                      Other
                    </Option>




                  </Select>
                </Form.Item>
                {serviceText === 2 ?
                  <div className="selectPara">
                    <Col span={24} md={12}>
                      <Form.Item
                        label="Vaccine Name"
                        name="serviceName"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {/* <p>Visit <a href="immunizebc.ca">immunizebc.ca</a> for more information</p> */}
                    {/* <p>Moderna COVID-19 SPIKEVAX BIVALENT Vaccine
                      Administration [Duration 15 Minutes]</p> */}
                    <p>
                      {/* Our offering of COVID-19 vaccination clinic days is currently
                      paused as a result of low demand. Future clinic days and times
                      will be added once the vaccine supply is secured, and the need
                      for vaccination is sufficient to minimize wastage. We thank you for
                      your understanding. */}

                    </p>

                  </div> : serviceText === 1 ? <div className="selectPara">
                    {/* <p> Influenza Vaccine Administration [Duration 15 Minutes]</p> */}
                    <p>
                      {/* Rx Care Pharmacy offers Seasonal Flu Shots */}

                      <p>Visit <a href="immunizebc.ca">immunizebc.ca</a> for more information</p>
                      {/* <p>Please wear a short sleeve and mask for your appointment.</p> */}
                    </p>

                  </div> : null}

              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Appointment Date"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Appointment Date*!",
                    },
                  ]}
                >
                  <DatePicker onChange={(e) => handleDateChange(e)} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Available time slot"
                  name="slot"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Available time slot**!",
                    },
                  ]}
                >
                  <Select showSearch placeholder="Select Time Slot" >
                    {slotData &&
                      slotData.slots.length > 0 &&
                      slotData.slots.map((data) => (
                        <Option value={data.form} key={data._id}>
                          {data.from + "-" + data.to}
                        </Option>
                      ))}
                  </Select>
                  {/* <TimePicker /> */}
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Location*"
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Rx care pharmacy & compounding",
                    },
                  ]}
                >
                  <Select>
                    <Option value="Rx care pharmacy & compounding">
                      Rx care pharmacy & compounding
                    </Option>
                  </Select>
                  {/* <Input /> */}
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please type your name*!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Mobile number"
                  name="phoneNumber"
                  rules={[
                    { required: true, message: "Please select your Mobile number!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please type your Email!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={24}>
                <div className="formSubmit">
                  <Button htmlType="submit" className="btn_primary">
                    Book Appoitment
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      <section className="number_wrap space-cls">
        <Container>
          <div className="number_box contact_sec">
            <Row className="align-items-center">
              <Col md={16}>
                <h3>You’ll need your Rx care Pharmacy account to sign up.</h3>
              </Col>
              <Col md={8}>
                <div className="contact_inner">
                  <Button onClick={() => contact()} className="btn btn_theme">
                    Contact Us
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Main>
  );
}

export default Appointment;
