import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import FooterLogo from "../../assets/images/footer-logo.png";
import Facebook from "../../assets/images/icon/facebook.svg";
import Instagram from "../../assets/images/icon/instagram.svg";
import Whatsapp from "../../assets/images/icon/whatsapp.svg";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <footer className="footer-bg">
      <div className="footer-top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-5 col-sm-6">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={FooterLogo} />
                  </Link>
                </div>
                <div className="f-contact">

                  <ul>
                    <li>
                      <a href="">
                        <img src={Facebook} />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img src={Instagram} />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img src={Whatsapp} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-sm-6">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-sm-12">
                  <div className="footer-widget">
                    <div className="f-widget-title">
                      <h2>Quick links</h2>
                    </div>
                    <div className="footer-link">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/contact-us"> Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/appointment">Appointment</Link>
                        </li>
                        <li>
                          <Link to="/quick-refill">Quick Refill</Link>
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-12">
                  <div className="footer-widget">
                    <div className="f-widget-title">
                      <h2>Working Hours</h2>
                    </div>
                    <div className="working-hours">
                      <p>Mon-Fri: 9:00 am - 6:00 pm</p>
                      <p>Sat: 10:00 am - 3:00 pm</p>
                      <p>Sunday Closed</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-12">
                  <div className="footer-widget">
                    <div className="f-widget-title">
                      <h2>Contact us</h2>
                    </div>
                    <div className="f-contact">
                      <ul>
                        <li>
                          <Link to="">
                            Rx Care Pharmacy 4695 Canada Way,
                            Burnaby, BC
                            V5G 1K9
                          </Link>
                        </li>
                        <li>
                          <Link to="tel:+1 604-428-7911">
                            Tel: 604.428.7911
                          </Link>
                        </li>
                        <li>
                          <Link to="tel:+1 604-428-7912">
                            Fax: 604.428.7912
                          </Link>

                        </li>
                        <li>
                          <Link to="info@rxcarepharmacy.ca">
                            info@rxcarepharmacy.ca
                          </Link>

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <Container>
          <div className="copyright-wrap">
            <p>© copyright 2023 rxcarepharmacy.com All Rights Reserved</p>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
