import {
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Modal,
  Form,
  Input,
  Tag,
  DatePicker,
  InputNumber,
  Select,
  Space,
  Menu,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BannerImg from "../assets/images/banner.jpg";
import arrowLEft from "../assets/images/icon/arrow-left.svg";
import arrowRight from "../assets/images/icon/arrow-right.svg";
import Serv1 from "../assets/images/icon/serv-1.svg";
import Serv2 from "../assets/images/icon/serv-2.svg";
import Serv3 from "../assets/images/icon/serv-3.svg";
import Serv4 from "../assets/images/icon/serv-4.svg";
import Serv5 from "../assets/images/icon/serv-5.svg";
import Serv6 from "../assets/images/icon/serv-6.svg";
import ServIcon from "../assets/images/icon/serv-1.svg";
import Prescription from "../assets/images/prescription.png";
import { Container, Row, Col } from "react-bootstrap";

const options = {
  loop: true,
  navText: [
    `<img src=${arrowLEft} alt='arrow'>`,
    `<img src=${arrowRight} alt='arrow'>`,
  ],
  nav: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

function IndexHome() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const [modalService, setModalService] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const navigate = useNavigate();

  const contact = () => {
    window.location.assign("/contact-us");
  };
  const quickRefill = () => {
    window.location.assign("/quick-refill");
  };
  const appointment = () => {
    window.location.assign("/appointment");
  };

  const getService = () => {
    request({
      url: `/app/appointment/service-list`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        // ShowToast(data.message, Severty.SUCCESS)
        setServiceList(data.data);
        // setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const getBanner = () => {
    request({
      url: `/common/banner-list`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        console.log(data.data);

        // ShowToast(data.message, Severty.SUCCESS)
        setBannerList(data.data);
        // setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getService();
    getBanner();
  }, []);

  return (
    <>
      <Main>
        {console.log(modalService, "length")}
        <section className="main-slider">
          <OwlCarousel className="owl-theme" {...options}>
            {bannerList
              ? bannerList.length > 0
                ? bannerList.map((data) => (
                  <div class="item">
                    <div className="banner-bg">
                      <div className="banner-img">
                        <img
                          src={
                            data.icon
                              ? "https://rxcarepharmacy.ca/api" + data.icon
                              : BannerImg
                          }
                          alt=""
                        />
                      </div>
                      <div className="banner-cont">
                        <div className="banner-inner-cont">
                          <h1>{data.name}</h1>
                          <p>{data.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : null
              : null}
            {/* <div class="item">
            <div className="banner-bg">
              <div className="banner-img">
                <img src={BannerImg} alt="" />
              </div>
              <div className="banner-cont">
                <div className="banner-inner-cont">
                  <h1>Ultimate Healthcare, Today And Tomorrow</h1>
                  <p>
                    It’s important to address your health conditions during
                    medications for the best substance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div className="banner-bg">
              <div className="banner-img">
                <img src={BannerImg} alt="" />
              </div>
              <div className="banner-cont">
                <div className="banner-inner-cont">
                  <h1>Ultimate Healthcare, Today And Tomorrow</h1>
                  <p>
                    It’s important to address your health conditions during
                    medications for the best substance.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          </OwlCarousel>
        </section>
        <section className="pharmacy-serv-sec space-cls">
          <Container>
            <div className="main-title">
              <h2>
                Rx care Pharmacy <span className="themeColor">Services</span>
              </h2>
              <p>
                It’s important to address your health conditions during
                medications for the best substance.
              </p>
            </div>
            <div className="serv-list">
              <Row>
                {serviceList && serviceList.length > 0
                  ? serviceList.map((data) => (
                    <Col md="6" lg="4">
                      <div className="serv-box">
                        <div className="serv-ic">
                          <img
                            src={
                              data.icon
                                ? "https://rxcarepharmacy.ca/api" + data.icon
                                : Serv1
                            }
                          />
                        </div>
                        <div className="serv-cont">
                          <h4>{data.name}</h4>
                          <p>{data.description}</p>
                          <Button
                            onClick={() => {
                              setModalService(true);
                              setServiceData(data);
                            }}
                          >
                            Read more...
                          </Button>
                          {/* <a href="#">Read more...</a> */}
                        </div>
                      </div>
                    </Col>
                  ))
                  : null}
                {/* <Col md="4">
                <div className="serv-box">
                  <div className="serv-ic">
                    <img src={Serv2} />
                  </div>
                  <div className="serv-cont">
                    <h4>Medication Review</h4>
                    <p>
                      Optimize medication and health review Do you have
                      questions that need answers? Our knowledgeable pharmacists
                      want to help you understand and optimize your medication
                      use. Book a private consultation to discuss your
                      prescription and non-prescription medications, monitor for
                      interactions, address side effects or improve the use of a
                      device.
                    </p>
                    <a href="#">Read more...</a>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="serv-box">
                  <div className="serv-ic">
                    <img src={Serv3} />
                  </div>
                  <div className="serv-cont">
                    <h4>Specialty Medication Packaging</h4>
                    <p>
                      Our Speciality Medication Packaging service can help to: –
                      Reduce confusion about your regimen – Remind you to take
                      your medications – Spread out your medicines over the day
                      – Reduce the risk for medication errors – Manage
                      medication refills – Monitor the medication use of a loved
                      one Specialty Medication Packs are available on a daily,
                      weekly, bi-weekly or monthly basis. A pharmacy team member
                      would be happy to help determine if this customized
                      service is exemplary for you!
                    </p>
                    <a href="#">Read more...</a>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="serv-box">
                  <div className="serv-ic">
                    <img src={Serv4} />
                  </div>
                  <div className="serv-cont">
                    <h4>Compounding</h4>
                    <p>
                      We can create specialized prescribed medications that are
                      not commercially available. We can also create medications
                      without additives for patients with allergies or special
                      preferences. We also create veterinarian medicines in
                      different dosage forms for pets.
                    </p>
                    <a href="#">Read more...</a>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="serv-box">
                  <div className="serv-ic">
                    <img src={Serv5} />
                  </div>
                  <div className="serv-cont">
                    <h4>Vaccine Administration</h4>
                    <p>
                      Our trained pharmacists administer many prescribed
                      vaccines in-store. Book appointments in advance to ensure
                      quick and convenient service.
                    </p>
                    <a href="#">Read more...</a>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="serv-box">
                  <div className="serv-ic">
                    <img src={Serv6} />
                  </div>
                  <div className="serv-cont">
                    <h4>Flu Shot Administration</h4>
                    <p>
                      Seasonal flu shots are offered annually. Call or check
                      in-store for up-to-date information on availability,
                      clinic days, and walk-in times.
                    </p>
                    <a href="#">Read more...</a>
                  </div>
                </div>
              </Col> */}
              </Row>
            </div>
          </Container>
        </section>
        <section className="prescription-sec space-cls">
          <Container>
            <Row>
              <Col md="6">
                <div className="prescription-cont">
                  <div className="main-title text-left">
                    <h2>
                      Refilling a{" "}
                      <span className="themeColor">Prescription</span>
                    </h2>
                    <p>
                      Skip the wait, refill online. Refill anywhere, any time
                    </p>
                  </div>
                  <ul>
                    <li>Submit refill requests online</li>
                    <li>
                      We’ll notify you when your prescription is ready at the
                      store
                    </li>
                    <li>
                      Your prescriptions will be ready for you in-store at the
                      pick-up counter
                    </li>
                  </ul>
                  <Button onClick={() => quickRefill()} className="btn_primary">
                    Quick Refill
                  </Button>
                </div>
              </Col>
              <Col md="6">
                <div className="prescription-img">
                  <img src={Prescription} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="number_wrap space-cls">
          <Container>
            <div className="number_box">
              <ul>
                <li>
                  <h3>10</h3>
                  <span>Years experience</span>
                </li>
                <li>
                  <h3>12k+</h3>
                  <span>Happy customers</span>
                </li>
                <li>
                  <h3>1k+</h3>
                  <span>Vaccine shot</span>
                </li>
                <li>
                  <h3>100%</h3>
                  <span>Honesty & transparency</span>
                </li>
              </ul>
            </div>
          </Container>
        </section>
        <section className="bookAppointment-sec space-sec">
          <Container>
            <div className="appointment-cont">
              <div className="main-title text-left">
                <h2>
                  Book Your <span className="themeColor">Appointment</span>
                </h2>
                <p>
                  Good services and better health by our specialists.
                </p>
                <Button onClick={() => appointment()} className="btn_primary">
                  Schedule Appointment
                </Button>
              </div>
            </div>
          </Container>
        </section>
        <section className="number_wrap space-cls">
          <Container>
            <div className="number_box contact_sec">
              <Row className="align-items-center">
                <Col md="8">
                  <h3>You’ll need your Rx care Pharmacy account to sign up.</h3>
                </Col>
                <Col md="4">
                  <div className="contact_inner">
                    <Button onClick={() => contact()} className="btn btn_theme">
                      Contact Us
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </Main>
      {modalService ? (
        <Modal
          visible={modalService}
          onCancel={() => setModalService(false)}
          footer={null}
          className="ModalCls"
        >
          <Row>
            <Col>
              <div className="ServiceDesc">
                <div className="serv-modal-icon">
                  <img
                    src={
                      serviceData && serviceData.icon
                        ? "http://62.72.3.159:3000" + serviceData.icon
                        : ServIcon
                    }
                  />
                </div>
                <div className="ServDesc">
                  <h2>{serviceData.name}</h2>
                  <p>{serviceData.description}</p>
                </div>
              </div>
              {/* {serviceData.description} */}
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
}

export default IndexHome;
