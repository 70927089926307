// import React from "react";
// import { Navigate } from "react-router-dom";

// const PrivateRoute = ({ children }) => {
//   console.log("------------hello world----------");
//   let token = localStorage.getItem("token")
//     ? localStorage.getItem("token")
//     : null;
//   console.log(token, "token------------");
//   return token ? children : <Navigate to="/expathy/login" />;
// };

// export default PrivateRoute;
// import React from "react";
// import { Navigate } from "react-router-dom";

// const PrivateRoute = ({ children }) => {
//   console.log("PrivateRoute - Checking for token...");
//   const token = localStorage.getItem("token");
//   console.log("PrivateRoute - Token:", token);

//   return token ? children : <Navigate to="/expathy/login" />;
// };

// export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import LoginModal from "../pages/Modal/LoginModal";

const PrivateRoute = ({ children }) => {
  console.log("afreen is her------");
  const [login, setLogin] = useState(false);
 
  let token = localStorage.getItem("userProfile")
    ? localStorage.getItem("userProfile")
    : null;
    useEffect(() => {
      token ? setLogin(false) : setLogin(true);
    }, [token]);
  return token ? (
    children
  ) : (
    <LoginModal show={login} hide={() => setLogin(false)} />
  );
};

export default PrivateRoute;
